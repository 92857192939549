import axios from "axios";
import * as R from "ramda";

const diffByDate = (aGame, anotherGame) => {
    return new Date(aGame.date).getTime() - new Date(anotherGame.date).getTime();
}
const getGamesSortedByDate = async () => {
    const response = await axios.get("/data/games.json", {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    });
    return R.sort(diffByDate, response.data);
}

export default getGamesSortedByDate;