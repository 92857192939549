import React, {useEffect, useState} from "react";
import getGamesSortedByDate from "./api/games";
import {Card, CardBody, CardText, CardTitle, Table} from "react-bootstrap";
import * as R from "ramda";

const fetchNext = (games) => {
    return games.find(g => new Date(g.date) > Date.now());
}

const fetchListOfTeams = R.pipe(
    R.map(R.props(['homeTeam', 'guestTeam'])), // Extract home and guest teams
    R.flatten, // Flatten into a single array
    R.uniq, // Get unique teams
    R.sortBy(R.complement(R.equals('Veteranen Oedelem'))) // Sort with "Veteranen Oedelem" first
);

const filterMatchesByTeam = (teamName) => R.filter(
    R.either(
        R.propEq(teamName,'homeTeam'),
        R.propEq(teamName, 'guestTeam')
    )
);

const formatDate = dateString => {
    if (!dateString) {
        return "";
    }
    let d = new Date(dateString);
    return d.getDate() + "/" + (d.getMonth()+1) + "/" + d.getFullYear();
}

const formatScore = game => {
    if (game.homeGoals < 0) {
        return "N/A"
    }
    return game.homeGoals + "-" + game.guestGoals;
}

const Games = () => {

    const [state, setState] = useState({
        games: [],
        nextGame: null,
        teams: []
    });

    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('Veteranen Oedelem');
    const [shownGames, setShownGames] = useState([]);

    const handleChangeFilter = event => {
        setSelectedTeam(event.target.value);
    }

    useEffect(() => {
        getGamesSortedByDate().then(data => {
            setState({games: data, nextGame: fetchNext(data)});
            setTeams(fetchListOfTeams(data));
            const filteredGames = filterMatchesByTeam(selectedTeam)(data);
            setShownGames(filteredGames);
        });
    }, [selectedTeam]);

    return (

        <div>

            <div>
                <h2>Volgende wedstrijd</h2>
                <Card>
                    <CardBody>
                        <CardTitle>{state.nextGame?.homeTeam} - {state.nextGame?.guestTeam}</CardTitle>
                        <CardText>{formatDate(state.nextGame?.date)}</CardText>
                    </CardBody>

                </Card>
            </div>
            <div>
                <h2>Wedstrijden</h2>
                <select data-cy="teamSelector" name="teamSelector" value={selectedTeam} onChange={handleChangeFilter}>
                    {teams.map((team, index) =>
                    <option key={index} value={team}>{team}</option>
                    )}
                </select>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Wedstrijd</th>
                        <th>Uitslag</th>
                    </tr>
                    </thead>
                    <tbody data-cy="listOfGames">
                    {shownGames.map((game, index) =>
                        <tr key={index}>
                            <td>{formatDate(game.date)}</td>
                            <td>{game.homeTeam} - {game.guestTeam}</td>
                            <td>{formatScore(game)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Games;