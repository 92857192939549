import * as R from "ramda";
import React, {useEffect, useState} from "react";
import getMembers from "./api/members"
import MembersPayed from "./MembersPayed";
import {countNrOfPayedMembers} from "./js/members";

const sortByName = R.sortWith([
    R.ascend(R.prop('idName')),
]);

const sortByGoals = R.sortWith([
    R.descend(R.prop('goals')),
    R.ascend(R.prop('name')),
]);

const sortByPresence = R.sortWith([
    R.descend(R.prop('games')),
    R.ascend(R.prop('name')),
]);

const MembersColumn = (props) => {
    return (
        <th onClick={props.onClick}>{props.title}</th>
    );
}

const MembersRow = (props) => {
    const bgColor = props.payed ? '' : 'red';

    const defaultToNotAvailable = (value) => {
        if (value === undefined) {
            return "N/A";
        }
        return value;
    }

    return (
        <tr key={props.index}>
            <td style={{
                backgroundColor: bgColor
            }}>{props.idName}</td>
            <td>{props.goals}</td>
            <td>{props.games}</td>
            <td>{props.started}</td>
            <td>{defaultToNotAvailable(props.substituted)}</td>
            <td>{defaultToNotAvailable(props.minutes)}</td>
            <td>{defaultToNotAvailable(props.points)}</td>
        </tr>
    );
}

const Members = () => {

    const [state, setState] = useState({
        members: []
    });
    const [amountMembersPayed, setAmountMembersPayed] = useState(0);
    const [amountMembers, setAmountMembers] = useState(0);

    const sortMembersByName = () => {
        setState(() => ({
            members: sortByName(state.members)
        }));
    }

    const sortMembersByGoals = () => {
        setState(() => ({
            members: sortByGoals(state.members)
        }));
    }

    const sortMembersByPresence = () => {
        setState(() => ({
            members: sortByPresence(state.members)
        }));
    }

    useEffect(() => {
        getMembers().then(data => {
            setState({members: sortByName(data)});
            setAmountMembersPayed(countNrOfPayedMembers(data));
            setAmountMembers(data.length);
        });
    }, []);

    return (
        <div>
            <h1 data-cy="nrOfMembers">Leden: {state.members.length}</h1>
            <MembersPayed payed={amountMembersPayed} total={amountMembers}/>
            <table data-cy="tableOfMembers">
                <thead>
                <tr>
                    <MembersColumn title={"Lid"} onClick={sortMembersByName}/>
                    <MembersColumn title={"Goals"} onClick={sortMembersByGoals}/>
                    <MembersColumn title={"Matchen"} onClick={sortMembersByPresence} />
                    <MembersColumn title={"Gestart"} />
                    <MembersColumn title={"Gewisseld"} />
                    <MembersColumn title={"Minuten"} />
                    <MembersColumn title={"Punten"} />
                </tr>
                </thead>
                <tbody data-cy="listOfMembers">
                {state.members.map((player, index) =>
                    <MembersRow key={index} index={index}
                                idName={player.idName}
                                payed={player.payed}
                                goals={player.goals}
                                games={player.games}
                                started={player.started}
                                substituted={player.substituted}
                                minutes={player.minutes}
                                points={player.points}/>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default Members